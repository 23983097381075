/* HTML: <div class="loader"></div> */
.loader {
    width: 32px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side,#4a4a4a 90%,#0000);
    background:
            var(--_g) 0%   50%,
            var(--_g) 50%  50%,
            var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

.oracleMessageContainer {
    animation: slideIn 0.6s ease-in-out !important;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}