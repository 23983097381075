.fadeIn {
	-webkit-animation: fadeIn 1s ease-in-out;
	-moz-animation: fadeIn 1s ease-in-out;
	-o-animation: fadeIn 1s ease-in-out;
	animation: fadeIn 1s ease-in-out;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}